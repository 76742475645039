import * as _ from "lodash";

const run = function (
  $window,
  $rootScope,
  Restangular,
  appInfo,
  $state,
  currentUser,
  $http,
  APP_CONFIG,
  ngToast,
  ngTableDefaults,
) {
  "ngInject";

  appInfo.init(APP_CONFIG);

  ngTableDefaults.params.count = 10;
  ngTableDefaults.settings.counts = [10, 25, 50, 100];

  Restangular.setErrorInterceptor(function (response, deferred, responseHandler) {
    if (response.status === 500) {
      if (Array.isArray(response.data.message)) {
        ngToast.danger("An error has occurred:  " + response.data.errors[0].message);
      } else {
        ngToast.danger("An error has occurred!");
      }
    } else {
      true;
    }
    if (response.status !== 422 && response.status !== 404) {
      if (response.config == null) {
        return;
      }
      if (response.status === 401) {
        // @ts-ignore
        if (!window.navigating) {
          return window.location.reload();
        }
      }
    }
  });

  $rootScope.$on("$stateChangeStart", function (e, toState) {
    if (toState.permissions && !currentUser.can(toState.permissions) && !currentUser.red_jade_admin) {
      e.preventDefault();
      // @ts-ignore
      window.location = "/home";
    }
  });

  Restangular["extend"] = function (dest, src) {
    _.merge(dest, src, function (a, b) {
      if (_.isArray(b)) {
        return b;
      }
    });
  };
};

export { run };
