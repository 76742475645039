participantListCtrl =
  (
    $q
    $scope
    ParticipantSvc
    EventSvc
    $stateParams
    $uibModal
    $rootScope
    Restangular
    ngToast
    participantStep
    appInfo
    $state
    participantSearchSvc
    $location
    NgTableParams
    $timeout
  ) ->
    "ngInject"

    vm = {}
    handlers = []

    init = ->
      participantSearchSvc.initializeFilterInfo($location.search())

      vm.tableParams = new NgTableParams({
        page: participantSearchSvc.data.filter_info.page
        count: participantSearchSvc.data.filter_info.per
        sorting:
          "#{participantSearchSvc.data.filter_info.order_by}": "#{participantSearchSvc.data.filter_info.order_dir}"
      }, {
        filterDelay: 300
        counts: [10, 25, 50, 100]
        getData: (params) ->
          # merged = _.extend($location.search(), $stateParams)
          deferred = $q.defer()
          post_data = {
            search_term: participantSearchSvc.data.filter_info.search_term
            page: if params then params.page() else 1
            per: if params then params.count() else 10000
            order_by: if params then params.orderBy()[0].substring(1) else 'code'
            order_dir: if params && params.orderBy()[0].substring(0,1) == '+' then 'asc' else 'desc'
            event_id: $stateParams.event_id
          }

          ParticipantSvc.all().customPOST(post_data, "search").then ((response) ->
            participantSearchSvc.data.total = response.meta.count
            params.total participantSearchSvc.data.total
            # $location.search(post_data)
            deferred.resolve(response)
          ),(response) ->
            deferred.resolve([])
          deferred.promise
      })

      participantSearchSvc.table = vm.tableParams

    exportUrl = ->
      return null unless participantStep.event?
      "#{appInfo.api_url}/sensory_tests/#{participantStep.event.sensory_test_id}/exports/participants?event_id=#{participantStep.event.id}"

    download = (click_event, test_event) ->
      # click_event.stopPropagation()
      Restangular.one("sensory_tests", test_event.sensory_test_id).customGET("exports/participants", {event_id: test_event.id}).then (response) ->
        RjEvent.emit('document_created', _.extend(response, {document_file_name: response.document_file_name}))

    edit = (participant) ->
      $uibModal.open(
        templateUrl: 'rj_shared/participant_edit.html'
        controller: 'participantEditCtrl'
        windowClass: 'uib-modal-medium'
        resolve:
          participant_id: -> participant.id
          subject_id: -> participant.subject_id
          sensory_test_id: -> participantStep.sensory_test.id
          event_id: -> participant.event_id
      ).result.then (response) ->
        vm.tableParams.reload()

    create = ->
      $uibModal.open(
        templateUrl: 'rj_shared/participant_edit.html'
        controller: 'participantEditCtrl'
        windowClass: 'uib-modal-medium'
        resolve:
          participant_id: -> null
          subject_id: -> null
          sensory_test_id: -> participantStep.sensory_test.id
          event_id: -> participantStep.event.id
      ).result.then (response) ->
        vm.tableParams.reload()

    deleteParticipants = () ->
      if participantSearchSvc.data.selected_participants.length > 0
        $uibModal.open(
          templateUrl: "rj_shared/dialog.html"
          controller: "dialogCtrl"
          resolve:
            settings: -> {
              title: "Confirm Participant Delete",
              message: "Delete #{participantSearchSvc.data.selected_participants.length} Participants?",
              style: "danger" ,
              ok_button: "Yes, Delete",
              cancel_button: "Cancel"
            }
        ).result.then (result) ->
          if result
            promises = []
            params = {id_list: participantSearchSvc.data.selected_participants}

            ParticipantSvc.all().customPOST(params, "batch_destroy").then (response) ->
              ngToast.success content: "Participants Deleted"
              participantSearchSvc.table.reload()
            , (err_response) ->
              ngToast.danger content: "An Error Has Occurred"


    sendInstructionsEmail = (participant) ->
      return if !participant.email || (vm.participantStep.event.checkin_type != 'no_checkin' && !participant.checked_in_at)

      ParticipantSvc.one(participant.id).customPOST({}, "send_instructions_email").then (res) ->
        ngToast.success content: "Email Sent"

    sendEventInstructionsEmail = (event_id) ->
      EventSvc.one(event_id).customPOST({}, "send_instructions_email").then (res) ->
        toastHelper.showSuccess("Emails Sent")

    getDemoValue = (participant, category) ->
      demos = _.filter(participant.demographics, (d) ->
        d.demographic_category_id is category.id
      )
      if demos.length > 0
        val = _.reduceRight demos,(a, b) ->
          return (a + b.name + ', ')
        , ''
        val = val.slice(0, -2)
        val
      else
        "     "

    sortBy = (propertyName) ->
      vm.sort_reverse = if (vm.sort_by == propertyName) then !vm.sort_reverse else false
      vm.sort_by = propertyName

    fetchEventDetails = (participant) ->
      unless participant.event_details
        ParticipantSvc.one(participant.id).customGET('event_details', { event_id: $stateParams.event_id }).then (result) ->
          participant.event_details = result

    bulkAdd = ->
      $uibModal.open(
        templateUrl: 'rj_shared/participant_bulk_add_modal.html'
        controller: 'participantBulkAddCtrl'
        resolve:
          event: -> participantStep.event
      ).result.then (response) ->
        vm.tableParams.reload()
        ngToast.success content: "Participant(s) Added"


    $scope.$watch('vm.merged_participants', (new_participants, old_participants) ->
      if new_participants
        vm.show_external_id = _.any(new_participants, (p) -> !!p.external_id)
    , true)

    $scope.$on 'destroy', () ->
      vm.merged_participants = []
      _.each(handlers, (closeHandler) -> closeHandler())

    last_reload = null
    exit_check = $rootScope.$on('LIST_SEARCH_CHANGED', (event, options) ->
      $timeout.cancel(last_reload) if last_reload
      last_reload = $timeout(->
        if options?.page
          vm.tableParams.page(options.page)
        else
          vm.tableParams.page(1)
        vm.tableParams.reload() unless $scope._willBeDestroyed
      , 300)
      return
    )
    $scope.$on('$destroy',(event) ->
      exit_check()
      return
    )

    saveEvent = ->
      participantStep.event.save().then (response) ->
        ngToast.success content: "Event Updated"
      , ->
        ngToast.danger content: "Failed to update checkin type"

    hiddenColumns = ->
      hidden = {}

      switch $state.current.url
        when '/participants'
          hidden.schedule_status = false

      hidden

    vm =
      hiddenColumns: hiddenColumns()
      participantStep: participantStep
      merged_participants: []
      exportUrl: exportUrl
      edit: edit
      create: create
      deleteParticipants: deleteParticipants
      sendInstructionsEmail: sendInstructionsEmail
      sendEventInstructionsEmail: sendEventInstructionsEmail
      getDemoValue: getDemoValue
      download: download
      sortBy: sortBy
      show_external_id: false
      participant_emails: null
      fetchEventDetails: fetchEventDetails
      bulkAdd: bulkAdd
      tooltip_open: true
      saveEvent: saveEvent
      field_site_checkin_options: [
        { value: 'no_checkin', label: 'No Check-in' }
        { value: 'checkin', label: 'Assisted Check-in' }
      ]
    $scope.vm = vm
    $scope.searchSvc = participantSearchSvc

    init()

export default participantListCtrl
