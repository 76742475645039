
constants = [
    { key: 'USD', value: 'United States Dollar', symbol: '$' }
    { key: 'EUR', value: 'Euro', symbol: '€' }
    { key: 'GBP', value: 'British Pound', symbol: '£' }
    { key: 'JPY', value: 'Japanese Yen', symbol: '¥' }
    { key: 'CHF', value: 'Swiss Franc', symbol: 'CHF' }
    { key: 'CAD', value: 'Canadian Dollar', symbol: 'C$' }
    { key: 'AUD', value: 'Australian Dollar', symbol: 'AU$' }
    { key: 'NZD', value: 'New Zealand Dollar', symbol: 'NZ$' }
    { key: 'HKD', value: 'Hong Kong Dollar', symbol: 'HK$' }
    { key: 'RUB', value: 'Russian Rouble', symbol: '₽' }
    { key: 'MXN', value: 'Mexican Peso', symbol: 'Mex$' }
    { key: 'ZAR', value: 'South African Rand', symbol: 'R' }
    { key: 'ALL', value: 'Albanian Lek', symbol: 'L' }
    { key: 'DZD', value: 'Algerian Dinar', symbol: 'DA' }
    { key: 'ARS', value: 'Argentine Peso', symbol: '$' }
    { key: 'AWG', value: 'Aruba Florin', symbol: 'ƒ' }
    { key: 'BSD', value: 'Bahamian Dollar', symbol: 'B$' }
    { key: 'BHD', value: 'Bahraini Dinar', symbol: 'BD' }
    { key: 'BDT', value: 'Bangladesh Taka', symbol: '৳' }
    { key: 'BBD', value: 'Barbados Dollar', symbol: 'Bds$' }
    { key: 'BYR', value: 'Belarus Ruble', symbol: 'Br' }
    { key: 'BZD', value: 'Belize Dollar', symbol: 'BZ$' }
    { key: 'BMD', value: 'Bermuda Dollar', symbol: '$' }
    { key: 'BTN', value: 'Bhutan Ngultrum', symbol: 'Nu.' }
    { key: 'BOB', value: 'Bolivian Boliviano', symbol: 'Bs' }
    { key: 'BWP', value: 'Botswana Pula', symbol: 'P' }
    { key: 'BRL', value: 'Brazilian Real', symbol: 'R$' }
    { key: 'BND', value: 'Brunei Dollar', symbol: 'B$' }
    { key: 'BGN', value: 'Bulgarian Lev', symbol: 'лв.' }
    { key: 'BIF', value: 'Burundi Franc', symbol: 'FBu' }
    { key: 'CNY', value: 'Chinese Yuan', symbol: '¥' }
    { key: 'KHR', value: 'Cambodia Riel', symbol: '៛' }
    { key: 'CVE', value: 'Cape Verde Escudo', symbol: '$' }
    { key: 'KYD', value: 'Cayman Islands Dollar', symbol: '$' }
    { key: 'XOF', value: 'CFA Franc (BCEAO)', symbol: 'CFA' }
    { key: 'XAF', value: 'CFA Franc (BEAC)', symbol: 'FCFA' }
    { key: 'CLP', value: 'Chilean Peso', symbol: '$' }
    { key: 'COP', value: 'Colombian Peso', symbol: '$' }
    { key: 'KMF', value: 'Comoros Franc', symbol: 'CF' }
    { key: 'CRC', value: 'Costa Rica Colon', symbol: '₡' }
    { key: 'HRK', value: 'Croatian Kuna', symbol: 'kn' }
    { key: 'CUP', value: 'Cuban Peso', symbol: '$MN' }
    { key: 'CZK', value: 'Czech Koruna', symbol: 'Kč' }
    { key: 'DKK', value: 'Danish Krone', symbol: 'kr' }
    { key: 'DJF', value: 'Djibouti Franc', symbol: 'Fdj' }
    { key: 'DOP', value: 'Dominican Peso', symbol: 'RD$' }
    { key: 'XCD', value: 'East Caribbean Dollar', symbol: '$' }
    { key: 'EGP', value: 'Egyptian Pound', symbol: 'E£' }
    { key: 'SVC', value: 'El Salvador Colon', symbol: '₡' }
    { key: 'EEK', value: 'Estonian Kroon', symbol: 'kr' }
    { key: 'ETB', value: 'Ethiopian Birr', symbol: 'Br' }
    { key: 'FKP', value: 'Falkland Islands Pound', symbol: '‎£' }
    { key: 'FJD', value: 'Fiji Dollar', symbol: '‎FJ$' }
    { key: 'IDR', value: 'Indonesian Rupiah', symbol: 'Rp' }
    { key: 'INR', value: 'Indian Rupee', symbol: '' }
    { key: 'GMD', value: 'Gambian Dalasi', symbol: 'D' }
    { key: 'GTQ', value: 'Guatemala Quetzal', symbol: 'Q' }
    { key: 'GNF', value: 'Guinea Franc', symbol: 'FG' }
    { key: 'GYD', value: 'Guyana Dollar', symbol: 'GY$' }
    { key: 'HTG', value: 'Haitian Gourde', symbol: 'G' }
    { key: 'HNL', value: 'Honduras Lempira', symbol: 'L' }
    { key: 'HUF', value: 'Hungarian Forint', symbol: 'Ft' }
    { key: 'ISK', value: 'Iceland Krona', symbol: 'Íkr' }
    { key: 'IRR', value: 'Iran Rial', symbol: '﷼' }
    { key: 'IQD', value: 'Iraqi Dinar', symbol: 'ع.د' }
    { key: 'ILS', value: 'Israeli Shekel', symbol: '₪' }
    { key: 'JMD', value: 'Jamaican Dollar', symbol: '$' }
    { key: 'JOD', value: 'Jordanian Dinar', symbol: '' }
    { key: 'KZT', value: 'Kazakhstan Tenge', symbol: '₸' }
    { key: 'KES', value: 'Kenyan Shilling', symbol: 'KSh' }
    { key: 'KRW', value: 'Korean Won', symbol: '₩' }
    { key: 'KWD', value: 'Kuwaiti Dinar', symbol: 'ك' }
    { key: 'LAK', value: 'Lao Kip', symbol: '₭' }
    { key: 'LVL', value: 'Latvian Lat', symbol: 'Ls' }
    { key: 'LBP', value: 'Lebanese Pound', symbol: 'ل.ل' }
    { key: 'LSL', value: 'Lesotho Loti', symbol: 'L' }
    { key: 'LRD', value: 'Liberian Dollar', symbol: 'L$' }
    { key: 'LYD', value: 'Libyan Dinar', symbol: 'LD' }
    { key: 'LTL', value: 'Lithuanian Lita', symbol: 'Lt' }
    { key: 'MOP', value: 'Macau Pataca', symbol: 'MOP$' }
    { key: 'MKD', value: 'Macedonian Denar', symbol: 'ден' }
    { key: 'MWK', value: 'Malawi Kwacha', symbol: 'MK' }
    { key: 'MYR', value: 'Malaysian Ringgit', symbol: 'RM' }
    { key: 'MVR', value: 'Maldives Rufiyaa', symbol: 'Rf' }
    { key: 'MRO', value: 'Mauritanian Ouguiya', symbol: 'UM' }
    { key: 'MUR', value: 'Mauritius Rupee', symbol: '₨' }
    { key: 'MDL', value: 'Moldovan Leu', symbol: '' }
    { key: 'MNT', value: 'Mongolian Tugrik', symbol: '₮' }
    { key: 'MAD', value: 'Moroccan Dirham', symbol: '.د.م' }
    { key: 'MMK', value: 'Myanmar Kyat', symbol: 'K' }
    { key: 'NAD', value: 'Namibian Dollar', symbol: 'N$' }
    { key: 'NPR', value: 'Nepalese Rupee', symbol: 'Rs' }
    { key: 'ANG', value: 'Neth Antilles Guilder', symbol: 'ƒ' }
    { key: 'NIO', value: 'Nicaragua Cordoba', symbol: 'C$' }
    { key: 'NGN', value: 'Nigerian Naira', symbol: '₦' }
    { key: 'KPW', value: 'North Korean Won', symbol: '₩' }
    { key: 'NOK', value: 'Norwegian Krone', symbol: 'kr' }
    { key: 'OMR', value: 'Omani Rial', symbol: 'ر.ع.' }
    { key: 'XPF', value: 'Pacific Franc', symbol: 'F' }
    { key: 'PKR', value: 'Pakistani Rupee', symbol: 'Rs' }
    { key: 'PAB', value: 'Panama Balboa', symbol: 'B/.' }
    { key: 'PGK', value: 'Papua New Guinea Kina', symbol: 'K' }
    { key: 'PYG', value: 'Paraguayan Guarani', symbol: '₲' }
    { key: 'PEN', value: 'Peruvian Nuevo Sol', symbol: 'S/' }
    { key: 'PHP', value: 'Philippine Peso', symbol: '₱' }
    { key: 'PLN', value: 'Polish Zloty', symbol: 'zł' }
    { key: 'QAR', value: 'Qatar Rial', symbol: 'QR' }
    { key: 'RON', value: 'Romanian New Leu', symbol: '' }
    { key: 'RWF', value: 'Rwanda Franc', symbol: 'FRw' }
    { key: 'WST', value: 'Samoa Tala', symbol: 'WS$' }
    { key: 'STD', value: 'Sao Tome Dobra', symbol: 'Db' }
    { key: 'SAR', value: 'Saudi Arabian Riyal', symbol: 'SR' }
    { key: 'SCR', value: 'Seychelles Rupee', symbol: 'SR' }
    { key: 'SLL', value: 'Sierra Leone Leone', symbol: 'Le' }
    { key: 'SGD', value: 'Singapore Dollar', symbol: 'S$' }
    { key: 'SBD', value: 'Solomon Islands Dollar', symbol: 'SI$' }
    { key: 'SOS', value: 'Somali Shilling', symbol: 'Sh.So.' }
    { key: 'LKR', value: 'Sri Lanka Rupee', symbol: 'රු' }
    { key: 'SHP', value: 'St Helena Pound', symbol: '£' }
    { key: 'SDG', value: 'Sudanese Pound', symbol: 'ج.س.' }
    { key: 'SZL', value: 'Swaziland Lilageni', symbol: 'L' }
    { key: 'SEK', value: 'Swedish Krona', symbol: 'kr' }
    { key: 'SYP', value: 'Syrian Pound', symbol: '' }
    { key: 'THB', value: 'Thai Baht', symbol: '฿' }
    { key: 'TRY', value: 'Turkish Lira', symbol: '₺' }
    { key: 'TWD', value: 'Taiwan Dollar', symbol: 'NT$' }
    { key: 'TZS', value: 'Tanzanian Shilling', symbol: 'TSh' }
    { key: 'TOP', value: 'Tonga Pa\'ang', symbol: 'T$' }
    { key: 'TTD', value: 'Trinidad Tobago Dollar', symbol: 'TT$' }
    { key: 'TND', value: 'Tunisian Dinar', symbol: 'DT' }
    { key: 'AED', value: 'UAE Dirham', symbol: 'د.إ' }
    { key: 'UGX', value: 'Ugandan Shilling', symbol: 'USh' }
    { key: 'UAH', value: 'Ukraine Hryvnia', symbol: '₴' }
    { key: 'UYU', value: 'Uruguayan New Peso', symbol: '$U' }
    { key: 'VUV', value: 'Vanuatu Vatu', symbol: 'VT' }
    { key: 'VND', value: 'Vietnam Dong', symbol: '₫' }
    { key: 'YER', value: 'Yemen Riyal', symbol: '﷼' }
    { key: 'ZMK', value: 'Zambian Kwacha', symbol: 'ZMW' }
    { key: 'ZWD', value: 'Zimbabwe dollar', symbol: '$' }
    { key: 'VEF', value: 'Venezuelan Bolivar', symbol: 'Bs.F.' }
    { key: 'UZS', value: 'Uzbekistan Sum', symbol: 'лв' }
    { key: 'KGS', value: 'Kyrgyzstan Som', symbol: 'лв' }
    { key: 'GHS', value: 'Ghanaian Cedi', symbol: 'GH₵' }
]

export default constants
