import * as _ from "lodash"

campaignSessionSvc =
  (
    moment
  ) ->
    "ngInject"

    getUniqueDatesWithSessions = (location_timezone_id, reservations, sessions) ->
      _(reservations).map((res) ->
          reservation_start = moment(res.start).tz(location_timezone_id)
          parts = reservation_start.toString().split(" ")
          {
            display: "#{parts[0]} #{parts[1]} #{parts[2]}"
            scientific_date: reservation_start.format('YYYY-MM-DD')
          }
        )
        .uniqBy('scientific_date')
        .map((date) ->
          date.sessions = _.select(sessions, (session) ->
            date.scientific_date == moment(session.start_time).tz(location_timezone_id).format('YYYY-MM-DD')
          )
          date
        )
        .value()

    {
      getUniqueDatesWithSessions: getUniqueDatesWithSessions
    }

export default campaignSessionSvc
