import * as _ from "underscore"
import cookies from "~angular/src/rj_shared/utils/cookies"
import "~angular/src/rj_shared/utils/urls"
import "~angular/src/rj_shared/utils/guid"
import "~angular/src/rj_shared/utils/pad"
import "~angular/src/rj_shared/utils/array"
import "~angular/src/rj_shared/utils/string_helpers"
import "~angular/src/rj_shared/utils/place_caret_at_end"
import "~angular/src/rj_shared/utils/object"
import "~angular/src/rj_shared/utils/globalUtils.ts"

const config = function (RestangularProvider, ngToastProvider, cfpLoadingBarProvider, $qProvider, $stateProvider) {
  "ngInject"

  $qProvider.errorOnUnhandledRejections(process.env.RAILS_ENV === "development" ? true : false)
  cfpLoadingBarProvider.includeSpinner = true
  cfpLoadingBarProvider.includeBar = true // includeSpinner does not consistently work unless this is set to true. Recruiting campaigns#new would not display the spinner without setting this to true, we hide it in rj_shared_stylesheets/stylesheets/theme/loading_bar.sass

  ngToastProvider.configure({
    animation: "fade",
    horizontalPosition: "right",
    verticalPosition: "bottom",
    maxNumber: 1,
  })

  RestangularProvider.setDefaultHeaders({
    "Content-Type": "application/json",
    Authorization: "Bearer " + cookies.getCookie("auth"),
  })
  RestangularProvider.setDefaultHttpFields({ withCredentials: true })
  RestangularProvider.setBaseUrl("/api/v1")
  RestangularProvider.setErrorInterceptor(function (response, deferred, responseHandler) {
    if (response.status === -1 || response.status === 502) {
      ngToastProvider.$get().danger({
        content:
          "Internet connectivity issues detected; your changes have not been saved. Check your internet connection and try again. Do not refresh this page.",
      })
      deferred.reject
      return false // error handled, custom interceptors won't run
    } else {
      return true
    }
  }) // error not handled

  RestangularProvider.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
    let extractedData = data
    if (_.isArray(data.response)) {
      extractedData = data.response
      extractedData["meta"] = {}
      _.each(data, function (v, k) {
        if (!_.includes(["pagination", "response"], k)) {
          return (extractedData.meta[k] = v)
        }
      })
      _.each(data.pagination, (v, k) => (extractedData.meta[k] = v))
    } else if (data.response !== undefined) {
      extractedData = data.response
    }
    return extractedData
  })

  RestangularProvider.setOnElemRestangularized(function (elem, isCollection, colname, Restangular) {
    if (isCollection) {
      elem.add = function (data) {
        data = Restangular.restangularizeElement(this.parentResource, data, colname)
        if (data.id) {
          data.fromServer = true
        }
        this.push(data)
        return data
      }

      elem.remove = function (data) {
        let index = undefined
        index = _.indexOf(this, data)
        if (index !== -1) {
          this.splice(index, 1)
        }
        return this
      }
    }

    return elem
  })
}

export { config }
