participantEditCtrl =
  (
    $scope
    ParticipantSvc
    $uibModalInstance
    $state
    ngToast
    DemographicCategorySvc
    SubjectSvc
    EventSvc
    $timeout
    $q
    validationHelper
    Restangular
    sensory_test_id
    event_id
    participant_id
    subject_id
  ) ->
    "ngInject"

    subject_search_data =
      include_archived: false
      page: 1
      per: 8

    promises = []
    promises.push(DemographicCategorySvc.all().getList({sensory_test_id: sensory_test_id, per: 'all'}))
    promises.push(EventSvc.one(event_id).get())
    promises.push(ParticipantSvc.one(participant_id).get()) if participant_id
    promises.push(SubjectSvc.one(subject_id).get()) if subject_id
    $q.all(promises).then ((results) ->
      vm.event = results[1]
      if participant_id
        vm.participant = results[2]
        vm.subject = results[3] if subject_id
      else
        vm.participant = ParticipantSvc.new({
          sensory_test_id: sensory_test_id,
          event_id: event_id,
          subject_id: subject_id
        })
      vm.demographic_categories = results[0]
      _.each vm.demographic_categories, (cat) ->
        cat.selected_ids = _.map(_.filter(vm.participant.demographics, (demo) -> demo.demographic_category_id == cat.id), "id")
        return
      if vm.event.location_id && participant_id
        cmd =
          location_id: vm.event.location_id
          per: "all"
          participant_id: participant_id
      return
    ), (result) ->
      return

    last_search = null
    search = (search_term) ->
      $timeout.cancel(last_search) if last_search

      last_search = $timeout(->
        subject_search_cmd =
          include_archived: false
          page: 1
          per: 8
          search_term: search_term
          exclude_with_participants: true
          sensory_test_id: sensory_test_id

        SubjectSvc.all().customPOST(subject_search_cmd, 'search').then (response) ->
          vm.subjects = response
      , 500)

    submit = () ->
      vm.participant.demographic_ids = _.flatten(_.map(_.filter(vm.demographic_categories, (thing) -> thing), "selected_ids"))
      vm.participant.save().then ((result) ->
        ngToast.success content: 'Participant Saved'
        $uibModalInstance.close result
        return
      ), (result) ->
        vm.errors = result.data.messages
        return

    close = ->
      $uibModalInstance.dismiss 'cancel'

    destroy = ->
      vm.participant.remove().then ->
        $uibModalInstance.close { action: 'deleted', id:vm.participant.id }
        ngToast.success content: 'Participant Deleted'

    subjectChanged = (subject) ->
      p = vm.participant
      s = subject || {}
      p.subject_id = s.id || null
      p.first_name = s.first_name || null
      p.middle_initial = s.middle_initial || null
      p.last_name = s.last_name || null
      p.name = s.name || null
      p.code = s.code || null
      p.email = s.email || null
      p.external_id = s.external_id || null
      p.phone = s.phone || null
      p.work_phone = s.work_phone || null
      p.mobile_phone = s.mobile_phone || null
      p.date_of_birth = s.date_of_birth || null
      p.note = s.notes || null

    neutral_outcomes = ['honorable_paid_discharge', 'not_scheduled']
    negative_outcomes = ['consent_declined', 'rescreen_fail', 'dishonorable_unpaid_discharge', 'dishonorable_paid_discharge']
    outcomeStatus = (interaction) ->
      if _.contains neutral_outcomes, interaction.outcome
        return 'neutral'
      else if _.contains negative_outcomes, interaction.outcome
        return 'negative'
      else
        return 'positive'

    vm = {
      participant: null
      event: null
      subject: null
      demographic_categories: []
      participant_interactions: []
      errors: null

      search: search
      submit: submit
      close: close
      destroy: destroy
      subjectChanged: subjectChanged
      outcomeStatus: outcomeStatus
    }
    $scope.vm = vm
    return

export default participantEditCtrl
