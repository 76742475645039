participantImportFileModalCtrl =
  (
    $uibModalInstance
    $scope
  ) ->
    "ngInject"

    submit = ->
      $uibModalInstance.close {
        match_name: vm.match_name
      }
      return

    cancel = ->
      $uibModalInstance.dismiss()
      return

    vm =
      match_name: false
      cancel: cancel
      submit: submit

    $scope.vm = vm

    return

export default participantImportFileModalCtrl
