import * as angular from "angular";

const redjade_templates = angular.module("redjade.templates", []).run(function ($templateCache) {
  "ngInject";
  // HTML
  $templateCache.put("rj_shared/email_composer_modal.html", require("./email_composer_modal.html"));
  $templateCache.put("rj_shared/event_history.html", require("./event_history.html"));
  $templateCache.put(
    "rj_shared/frequency_distribution_settings.html",
    require("./frequency_distribution_settings.html"),
  );
  $templateCache.put("rj_shared/fresh_desk_widget.html", require("./fresh_desk_widget.html"));
  $templateCache.put("rj_shared/firefox_warn_copy_modal.html", require("./firefox_warn_copy_modal.html"));
  $templateCache.put("rj_shared/list.html", require("./list.html"));
  $templateCache.put("rj_shared/list_search.html", require("./list_search.html"));
  $templateCache.put("rj_shared/message_list.html", require("./message_list.html"));
  $templateCache.put("rj_shared/participant_bulk_add_modal.html", require("./participant_bulk_add_modal.html"));
  $templateCache.put("rj_shared/participant_edit.html", require("./participant_edit.html"));
  $templateCache.put("rj_shared/participant_import.html", require("./participant_import.html"));
  $templateCache.put("rj_shared/participant_import_file_modal.html", require("./participant_import_file_modal.html"));
  $templateCache.put("rj_shared/participant_list.html", require("./participant_list.html"));
  $templateCache.put("rj_shared/sensory_tests/filter_modal.html", require("./sensory_tests/filter_modal.html"));
  $templateCache.put("rj_shared/sensory_tests/list_options.html", require("./sensory_tests/list_options.html"));
  $templateCache.put("rj_shared/sidebarset.html", require("./sidebarset.html"));
  $templateCache.put("rj_shared/support_tab.html", require("./support_tab.html"));
  $templateCache.put("rj_shared/tag_edit.html", require("./tag_edit.html"));
  $templateCache.put("rj_shared/tag_modal.html", require("./tag_modal.html"));
  $templateCache.put("rj_shared/top_nav.html", require("./top_nav.html"));
  $templateCache.put("rj_shared/typeahead/demographic.html", require("./typeahead/demographic.html"));
  $templateCache.put("rj_shared/typeahead/demographic_category.html", require("./typeahead/demographic_category.html"));
  $templateCache.put("rj_shared/typeahead/location.html", require("./typeahead/location.html"));
  $templateCache.put("rj_shared/typeahead/panel.html", require("./typeahead/panel.html"));
  $templateCache.put("rj_shared/typeahead/user.html", require("./typeahead/user.html"));
  $templateCache.put("rj_shared/listEditorWrapper.html", require("./listEditorWrapper.html"));
  $templateCache.put("rj_shared/listEditorBuilderWrapper.html", require("./listEditorBuilderWrapper.html"));
  return $templateCache.put("rj_shared/wizard_nav.html", require("./wizard_nav.html"));
});

export default redjade_templates;
