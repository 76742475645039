participantBulkAddCtrl =
  (
    $uibModalInstance
    event
    $scope
  ) ->
    "ngInject"

    submit = ->
      event.customPOST({email_addresses: vm.participant_emails}, "batch_add_participants").then (res) ->
        $uibModalInstance.close()

    cancel = ->
      $uibModalInstance.dismiss()

    vm =
      paricipant_emails: null

      submit: submit
      cancel: cancel
    $scope.vm = vm

export default participantBulkAddCtrl
