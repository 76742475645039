import cookies from "~angular/src/rj_shared/utils/cookies"
d3 = require("d3")
d3_scale = require("d3-scale")

participantImportCtrl =
  (
    $scope
    $state
    $stateParams
    Restangular
    ngToast
    appInfo
    $q
    FileUploader
    EventSvc
    NgTableParams
    $rootScope
    $uibModal
    $timeout
  ) ->
    "ngInject"

    pusher = new Pusher(
      window.PusherKey.calculate(),
      window.PusherAuth.calculate('v1')
    )
    channel = null
    pending_changes = false

    data = {
      file_properties: {
        match_name: false
      }
    }

    init = ->
      initUploader()
      configureWebSocket()

      $q.all([
        EventSvc.one($stateParams.event_id).get()
        Restangular.all("participant_imports").customPOST({
          event_id: $stateParams.event_id,
          per: 2,
          order_by: 'created_at',
          order_dir: 'desc',
          import_type: 'file'
        }, 'search')
        Restangular.all("participant_imports").customGET('lookup')
      ]).then (results) ->
        vm.event = results[0]
        vm.loading = false
        vm.recent_imports = results[1]
        # vm.select_import = results[1]
        vm.column_summaries = results[2]
        vm.column_summary_table_params = new NgTableParams(
          { count: 10 },
          {
            getData: () -> return vm.column_summaries
            counts: []
          }
        )

    initUploader = ->
      vm.step = 'browsing'
      uploader = vm.uploader = new FileUploader({
        url: "#{appInfo.api_url}/participant_imports?event_id=#{$stateParams.event_id}"
        withCredentials: true
        headers:
          'Authorization': 'Bearer '+ cookies.getCookie('auth')
      })

      uploader.onBeforeUploadItem = (fileItem) ->
        fileItem.formData = [data.file_properties]
        return

      uploader.onAfterAddingFile = (fileItem) ->
        vm.step = 'uploading'
        vm.message = null
        vm.error = null
        if vm.event.actual_participant_count == 0
          fileItem.upload()
        else
          $uibModal.open(
            templateUrl: 'rj_shared/participant_import_file_modal.html'
            controller: 'participantImportFileModalCtrl'
          ).result.then (file_properties) ->
            data.file_properties = file_properties
            fileItem.upload()
          , ->
            vm.step = 'browse'

            # this is necessary in order to reload the directive for some reason
            vm.select_import = {}
            $timeout(->
              vm.select_import = null
              return
            )

      uploader.onCompleteItem = (fileItem, result, status, headers) ->
        vm.step = 'uploaded'
        select_import = Restangular.restangularizeElement(null, result.response, "participant_imports")
        selectImport(select_import)
        setPendingChanges()

      uploader.onErrorItem = (fileItem, result, status, headers) ->
        vm.select_import_error = _.first(result.response.messages)
        vm.loading = false
        vm.loading_message = null

      return

    submit = ->
      vm.error = null
      vm.show_import = false
      data = _.extend({}, vm.action, { participant_import_id: vm.select_import.id })
      Restangular.all('participant_import_actions').post(data).then ((result) ->
        vm.action = result
      ), (error) ->
        vm.show_import = true
        vm.errors = error.data.messages.base
        setPendingChanges()
      return

    submitDisabled = ->
      return true unless vm.select_import
      false

    reUploadImport = ->
      # if !vm.action
        # vm.select_import.remove()
      vm.select_import = null
      vm.parse = null
      vm.action = null
      return

    setParseViewModel = ->
      vm.invalid_column_summaries = _.select(vm.parse.column_summaries, (cs) -> _.any(cs.invalid_rows))
      vm.parsed_column_summary_table_params = new NgTableParams(
        { sorting: { column_number: 'asc'  }, count: 10 },
        {
          getData: () ->  vm.parse.column_summaries
          counts: []
        }
      )

      missing_categories = _.map(vm.parse.missing_categories, (mc, key) -> mc)
      vm.missing_categories_table_params = new NgTableParams(
        { sorting: { name: 'asc'  }, count: 10 },
        {
          getData: () -> missing_categories
          counts: []
        }
      )

      vm.participant_table_params = new NgTableParams(
        {
          sorting: { row_number: 'asc' }
          count: 10
          page: 1
        }
        {
          getData: (params) -> vm.parse.participants.slice((params.page() - 1) * params.count(), params.page() * params.count())
          counts: []
          total: vm.parse.participants.length
        }
      )
      vm.parse_status = 'complete'
      return

    loadParser = ->
      Restangular.one('participant_imports', vm.select_import.id).customGET('parser').then (result) ->
        vm.parse = result
        setParseViewModel()
        return

    copySuccess = (cell_excel) ->
      vm.last_cell_excel = cell_excel
      ngToast.success content: "'#{cell_excel}' copied to clipboard"
      return

    copyFail = ->
      ngToast.danger content: "Failed to copy"
      return

    configureWebSocket = ->
      pusher.unsubscribe(channel.name) if channel
      pusherChannelPrefix = process.env.PUSHER_CHANNEL_PREFIX
      channel = pusher.subscribe("private-#{pusherChannelPrefix}user_#{cookies.getCookie('auth')}")
      channel.bind 'participant_import', participantImportWebsocketHandler
      return

    $scope.$on('$destroy',(event) ->
      pusher.disconnect() if pusher
      return
    )

    participantImportWebsocketHandler = (data) ->
      if data.event == 'parse_complete'
        loadParser()
      else if data.event == 'import_progress'
        $scope.$apply(->
          vm.action = data if data.participant_import_id == vm.select_import.id
          pending_changes = false if vm.action.status == 'complete'
        )
      return

    participantImportEventHandler = (data) ->
      vm.action = data

    cancel = ->
      $state.go($stateParams.return_state, _.extend({ event_id: vm.event.id }, $stateParams))

    download = ->
      return unless vm.select_import

      vm.select_import.customPOST({}, 'export').then (result) ->
        RjEvent.emit('document_created', _.extend(result, {document_file_name: vm.select_import.name + 'Export'}))

    setPendingChanges = ->
      pending_changes = vm.select_import && (!vm.action || vm.action.status == 'pending')
      return

    selectImport = (imp) ->
      vm.select_import = imp
      vm.parse_status = 'loading' # | working | complete
      loadParser()
      last_action_params = {
        participant_import_id: vm.select_import.id
        per: 1
        order_by: 'created_at'
        order_dir: 'desc'
      }
      Restangular.all('participant_import_actions').customPOST(last_action_params, 'search').then (result) ->
        vm.action = _.first(result)
        if vm.action
          vm.show_import = false
        else
          vm.action = {
            participant_import_id: vm.select_import.id
            save_missing_categories: true
            only_demographics: false
            destroy_existing_demographics: false
            destroy_unmatched_participants: false
            skip_invalid: false
            skip_duplicate_identity_matches: false
          }
        setPendingChanges()

    refreshImports = (term) ->
      Restangular.all("participant_imports").customPOST({
        event_id: $stateParams.event_id,
        per: 10
        order_by: 'created_at'
        order_dir: 'desc'
        search_term: term
        import_type: 'file'
      }, 'search').then (result) ->
        vm.searched_participant_imports = result
        return vm.searched_participant_imports

    noIdentityColumns = ->
      return false unless vm.parse

      !_.any(vm.parse.column_summaries, (cs) ->
        _.include(['email', 'external_id', 'name'], cs.field)
      )

    anySkippedColumns = ->
      return false unless vm.parse

      _.any(vm.parse.skipped_columns)

    exit_check = $rootScope.$on '$stateChangeStart', (event, toState, toParams, fromState, fromParams) ->
      if pending_changes # && toState.name != "projects.dashboard.sensory_test.wizard.questionnaires.edit"
        event.preventDefault()
        $uibModal.open(
          templateUrl: 'rj_shared/dialog.html'
          controller: 'dialogCtrl'
          resolve:
            settings: -> {
              title: 'Unsaved Changes!',
              message: 'Do you still want to leave?',
              style: 'danger',
              ok_button: 'Yes, Leave'
            }
        ).result.then (result) ->
          if result
            pending_changes = false
            $state.go(toState)

    $scope.$on('$destroy',(event) ->
      exit_check()
      return
    )

    vm = {
      uploader: null
      loading: true
      show_all_columns: true
      select_import: null
      parse_status: 'loading' #| 'working' | 'complete'
      submit: submit
      cancel: cancel
      error: null
      column_summary_table_params: null
      parsed_column_summary_table_params: null
      show_import: true
      selected_participant_import: null
      selected_participant_import_id: null
      action: null
      recent_imports: []
      participant_table_params: null
      color_scale: d3.scale.category20()

      reUploadImport: reUploadImport
      selectImport: selectImport
      refreshImports: refreshImports
      submitDisabled: submitDisabled
      copySuccess: copySuccess
      copyFail: copyFail
      download: download
      noIdentityColumns: noIdentityColumns
      anySkippedColumns: anySkippedColumns
    }
    $scope.vm = vm

    init()

    return

export default participantImportCtrl
