import * as angular from "angular"

link_shares = angular.module('redjade.ui.link_shares', [])
  .controller('linkSharesAuthCtrl', require("./link-shares-auth.controller").default)
  .controller('linkSharesEditModalCtrl', require("./link-shares-edit-modal.controller.ts").default)
  .run(($templateCache) ->
    "ngInject"
    $templateCache.put('rj_shared/link_shares/auth.html', require("./auth.html"))
    $templateCache.put('rj_shared/link_shares/edit-modal.html', require("./edit-modal.html"))

  )
  .name
export default link_shares
