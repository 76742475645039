sensoryTestListCtrl =
  (
    $q
    $state
    $scope
    $timeout
    $location
    $rootScope
    $stateParams
    ngToast
    NgTableParams
    SensoryTestSvc
    sensoryTestSearchSvc
    appInfo
  ) ->
    "ngInject"

    # initialize table state, shared with subjectFilterListCtrl
    locationChangeSuccess = $scope.$on '$locationChangeSuccess', (value) ->
      sensoryTestSearchSvc.buildFilters()

    $scope.$on '$destroy', ->
      locationChangeSuccess()

    init = ->
      sensoryTestSearchSvc.initializeFilterInfo($location.search())

      promises = []
      sensoryTestSearchSvc.data.filter_info.order_by = 'created_at'
      sensoryTestSearchSvc.data.filter_info.order_dir = 'desc'

      vm.tableParams = new NgTableParams({
        page: sensoryTestSearchSvc.data.filter_info.page
        count: sensoryTestSearchSvc.data.filter_info.per
        sorting:
          "#{sensoryTestSearchSvc.data.filter_info.order_by}": "#{sensoryTestSearchSvc.data.filter_info.order_dir}"
      }, {
        filterDelay: 300
        getData: (params) ->
          merged = _.extend($location.search(), $stateParams)
          post_data = _.extend(sensoryTestSearchSvc.getSearchData(merged), {
            page: if params then params.page() else 1
            per: if params then params.count() else 10000
            order_by: if params then params.orderBy()[0].substring(1) else 'sensory_tests.created_at'
            order_dir: if params && params.orderBy()[0].substring(0,1) == '+' then 'asc' else 'desc'
          })

          SensoryTestSvc.all().customPOST(post_data, "search").then ((response) ->
            sensoryTestSearchSvc.data.total = response.meta.count
            params.total sensoryTestSearchSvc.data.total
            post_data.sensory_test_id ||= parseInt(merged.sensory_test_id)
            $location.search(post_data)
            response
          ),(response) ->
            []
      })

    startExport = ->
      merged = _.extend($location.search(), $stateParams)
      post_data = sensoryTestSearchSvc.getSearchData(merged)
      SensoryTestSvc.all().customPOST(post_data, 'export').then (response) ->
        RjEvent.emit('document_created', _.extend(response, {document_file_name: 'Sensory Test Export'}))

    last_reload = null
    exit_check = $rootScope.$on('LIST_SEARCH_CHANGED', (_event, options) ->
      $timeout.cancel(last_reload) if last_reload
      last_reload = $timeout(->
        if options?.page
          vm.tableParams.page(options.page)
        else
          vm.tableParams.page(1)
        vm.tableParams.reload() unless $scope._willBeDestroyed
      , 300)
      return
    )
    $scope.$on('$destroy',(event) ->
      exit_check()
      return
    )

    vm = {
      filter_info: {}
      translations: {}
      tests: []
      startExport: startExport
      appInfo: appInfo
    }
    $scope.vm = vm
    $scope.searchSvc = sensoryTestSearchSvc

    init()

export default sensoryTestListCtrl
