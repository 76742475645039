eventHistoryCtrl =
  (
    $scope
    $state
    $stateParams
    Restangular
    ngToast
    $q
    EventSvc
    NgTableParams
    $rootScope
  ) ->
    "ngInject"

    init = ->
      $q.all([
        EventSvc.one($stateParams.event_id).get()
        Restangular.all('participant_import_actions').getList(event_id: $stateParams.event_id)
      ]).then (results) ->
        vm.loading = false
        vm.event = results[0]
        vm.actions = results[1]
        vm.action_table_params = new NgTableParams(
          { sorting: { created_at: 'desc'  }, count: 10 },
          {
            getData: () -> vm.actions
            counts: []
          }
        )

    download = (participant_import_action) ->
      Restangular.one('participant_imports', participant_import_action.participant_import_id).customPOST({}, 'export').then (result) ->
        RjEvent.emit('document_created', _.extend(result, {document_file_name: participant_import_action.participant_import_name}))

    cancel = ->
      $state.go($stateParams.return_state, _.extend({ event_id: vm.event.id }, $stateParams))

    vm = {
      event: null
      action_table_params: null
      download: download
      cancel: cancel
    }
    $scope.vm = vm

    init()
    return

export default eventHistoryCtrl
