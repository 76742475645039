import * as angular from "angular"

participants = angular.module('redjade.ui.participants', [])
  .controller('eventHistoryCtrl', require("./eventHistory.controller").default)
  .controller('participantBulkAddCtrl', require("./participantBulkAdd.controller").default)
  .controller('participantEditCtrl', require("./participantEdit.controller").default)
  .controller('participantImportCtrl', require("./participantImport.controller").default)
  .controller('participantImportFileModalCtrl', require("./participantImportFileModal.controller").default)
  .controller('participantListCtrl', require("./participantList.controller").default)
  .name

export default participants