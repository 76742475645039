import * as _ from "lodash";

const linkShareEditModalCtrl = function (
  $scope,
  $uibModalInstance,
  LinkShareSvc,
  $q,
  $state,
  linkShareParams,
  ngToast,
  messageHelper,
) {
  "ngInject";

  const init = () => {
    let promises = [LinkShareSvc.all().getList(linkShareParams)];
    $q.all(promises).then(results => {
      vm.link_shares = results[0];
    });
  };

  const getShareURL = link => {
    return $state.href("link_sharing", { access_key: link.access_key }, { absolute: true });
  };

  const create = link => {
    LinkShareSvc.new(linkShareParams)
      .save()
      .then(
        result => {
          vm.link_shares.push(result);
        },
        result => {
          ngToast.danger({ content: messageHelper.firstMessage(result.data.messages) });
        },
      );
  };

  const save = link => {
    link.save().then(
      result => {},
      result => {
        ngToast.danger({ content: messageHelper.firstMessage(result.data.messages) });
      },
    );
  };

  const remove = link => {
    link.remove().then(
      result => {
        const index = vm.link_shares.indexOf(link);
        if (index > -1) {
          vm.link_shares.splice(index, 1);
        }
      },
      result => {
        ngToast.danger({ content: messageHelper.firstMessage(result.data.messages) });
      },
    );
  };

  const toggleArchived = link => {
    if (link.archived_at) link.archived_at = null;
    else link.archived_at = new Date();
    vm.save(link);
  };

  const copySuccess = () => {
    ngToast.success({ content: "URL copied" });
    return;
  };

  const cancel = (): void => {
    $uibModalInstance.dismiss();
  };

  var vm = {
    link_shares: null,
    getShareURL,
    copySuccess,
    toggleArchived,
    save,
    remove,
    create,
    cancel,
  };

  $scope.vm = vm;
  init();
};

export default linkShareEditModalCtrl;
