linkSharesAuthCtrl =
  (
    $scope
    $state
    $stateParams
    LinkShareSvc
  ) ->
    "ngInject"

    vm =
      loading: true

    LinkShareSvc.current().get().then (result) ->
      if result.state
        $state.go(result.state, result.state_params, { location: false } )

      vm.loader = false
    , (result) ->
      vm.loader = false


    $scope.vm = vm

export default linkSharesAuthCtrl
