sensoryTestFilterModalCtrl =
  (
    $scope
    $uibModalInstance
    UserSvc
    $q
    locations
    creator
    filter_info
  ) ->
    "ngInject"

    cancel = ->
      $uibModalInstance.dismiss()

    save = ->
      location_ids = _.map(_.filter(vm.locations, {selected: true}), (l) -> l.id)
      $uibModalInstance.close {
        location_ids: location_ids
        active_after: vm.active_after
        active_before: vm.active_before
        created_by_id: vm.created_by_id
        status_greater_than_or_equal_to: if vm.status_launched then 'launched' else null
      }

    searchUsers = (search_term) ->
      search_query =
        search_term: search_term
        page: 1
        per: 5
      UserSvc.all().customGET('search', search_query).then (response) ->
        vm.users = response

    clearAllFilters = ->
      _.each(vm.locations, (l) -> l.selected = false; return)
      vm.active_before = null
      vm.active_after = null
      vm.created_by_id = null
      vm.creator = null
      vm.status_greater_than_or_equal_to = null

    vm = {
      locations: angular.copy(locations)
      active_before: if filter_info.active_before then moment(filter_info.active_before).toDate() else null
      active_after: if filter_info.active_after then moment(filter_info.active_after).toDate() else null
      creator: creator
      created_by_id: creator?.id
      status_launched: filter_info.status_greater_than_or_equal_to == 'launched'

      cancel: cancel
      save: save
      searchUsers: searchUsers
      clearAllFilters: clearAllFilters
    }
    $scope.vm = vm


    # Init
    ## locations
    selected_locations = _.select(vm.locations, (l) ->
      _.includes(filter_info.location_ids, l.id)
    )
    _.each(selected_locations, (l) -> l.selected = true; return)
    ## users
    searchUsers()

export default sensoryTestFilterModalCtrl
